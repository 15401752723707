import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { RootStore, User } from 'src/app/store/root.store';

@Component({
  selector: 'hh-buzz-iframe',
  templateUrl: './buzz-iframe.component.html',
  styleUrls: ['./buzz-iframe.component.scss']
})
export class BuzzIFrameComponent implements OnInit, OnChanges{
  @Input() url!: string;
  isLoading = true;
  loginLoading = false;
  isLogin = false;
  baseUrl = 'https://buzz.skyscape.com/conversations';

  constructor(
    private userService: UserService, 
    private store: RootStore,
  ) {}

  public buzzLoginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    passphrase: new FormControl('', [Validators.required])
  });

  ngOnInit(): void {
    const user = this.store.currentUser$.getValue();
    if(user?.buzzToken && user.buzzToken != ''){
      this.reloadBuzzFrame(user?.buzzToken);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes,'ngOnChanges');
    this.url = changes['url'].currentValue;
    // if (changes['url'] && changes['url'].currentValue !== changes['url'].previousValue) {
    // }
  }


  onBuzzLogin(){
    this.loginLoading = true;
    const user = this.store.currentUser$.getValue();
    if(user?.buzzToken){
      this.reloadBuzzFrame(user?.buzzToken);
    }else{
      this.userService.buzzLogin(this.buzzLoginForm.value).subscribe(data=>{
        this.store.currentUser$.next({ ...user, buzzToken: data?.token } as User);
        this.reloadBuzzFrame(data?.token);
      },
      error => {
        this.loginLoading = false;
        this.store.bannerMessage$.next('Username, password, or passphrase is incorrect. Please verify your details and try again.');
        this.store.bannerState$.next('error');
        this.store.showBanner$.next(true);
      })
    }
  }
  reloadBuzzFrame(token: string) {
    console.log('on reload');
    if(token){
      this.loginLoading =false;
      this.isLogin = true;
      const encodedToken = encodeURIComponent(token);
      if(this.url.indexOf('sessions') === -1){
        this.url = `${this.baseUrl}?token=${encodedToken}`;
      }
    }
  }

  onIframeLoad(): void {
    this.isLoading = false;
  }
}