<div class="iframe-container">
    <!-- Loader -->

    <ng-container *ngIf="!isLogin">
        <form [@slideLeftOnEnter] (ngSubmit)="onBuzzLogin()" class="login--form" [formGroup]="buzzLoginForm">
            <label>Email*</label>
            <input
                class="full"
                type="email"
                value=""
                formControlName="email"
            />
            <label>Password*</label>
            <input
                class="full"
                type="password"
                value=""
                formControlName="password"
            />
            <label>Passphrase*</label>
            <input
                class="full"
                type="password"
                value=""
                formControlName="passphrase"
            />
            <button [disabled]="!buzzLoginForm.valid" type="submit" class="button login--button full primary">Login <div *ngIf="loginLoading" class="loader small"></div></button>
        </form>
    </ng-container>
    <!-- Iframe -->
    <ng-container *ngIf="isLogin">
        <div *ngIf="isLoading" class="loader"></div>
        <iframe
            [src]="url | safeUrl"
            frameborder="0"
            (load)="onIframeLoad()">
        </iframe>
    </ng-container> 
    
</div>